.customHeading {
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}

.customHeading + .customHeading {
  margin-top: 8px;
}

.customHeading > * {
  margin: 0;
}

.highlight {
  font-style: normal;
  color: var(--color-highlight, var(--color-pink));
}

.gradient {
  --bgX: '50%';
  --bgY: '50%';
  background-color: var(--color-text);
  background-image: url('/text-gradient.jpg');
  background-size: 35em;
  background-position: var(--bgX) var(--bgY);
  background-repeat: repeat;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;

}
